import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Container,
  Content,
  Footer,
  Header,
  Layout,
  Root,
} from '../components';

export type Page = {
  title: string;
  path: string;
  content: Content;
  seo: {
    title: string;
  };
};

const Page: React.FC<PageProps<null, { page: Page; pages: Page[] }>> = ({
  pageContext: { page, pages },
}) => (
  <>
    <Helmet>
      <title>{page?.seo?.title || `Yes2chess - ${page.title}`}</title>
    </Helmet>
    <Root>
      <Layout>
        <Header pages={pages}>
          <Container>
            <Content data={page.content} />
          </Container>
          <Footer />
        </Header>
      </Layout>
    </Root>
  </>
);

export default Page;
